import React from "react";
import ReactMarkdown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProcessSlide = ({ index, processData }) => {
  const { sectionTitle, description, image } = processData;
  const createdImage = getImage(image);
  return index % 2 !== 0 ? (
    <li className="list-item col-md-6 align-self-end mb-4">
      <div className="media ml-5 p-3">
        <div
          className="rounded-circle bg-white shadow-success text-contrast p-3  d-flex align-items-center justify-content-center position-absolute"
          style={{ left: "-44px" }}
        >
          <GatsbyImage
            image={createdImage}
            alt={`${sectionTitle} process image`}
          />
        </div>
        <div className="media-body ">
          <h5 className="bold ">{sectionTitle}</h5>
          <ReactMarkdown source={description} className="my-0" />
        </div>
      </div>
    </li>
  ) : (
    <li className="list-item col-md-6 align-self-start mb-4">
      <div className="media mr-5 p-3">
        <div className="media-body text-right ">
          <h5 className="bold ">{sectionTitle}</h5>
          <ReactMarkdown source={description} className="my-0" />
        </div>
        <div
          className="rounded-circle bg-white shadow-success text-contrast p-3  d-flex align-items-center justify-content-center position-absolute"
          style={{ right: "-44px" }}
        >
          <GatsbyImage
            image={createdImage}
            alt={`${sectionTitle} process image`}
          />
        </div>
      </div>
    </li>
  );
};

export default ProcessSlide;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Swiper from "react-id-swiper";
import SliderBlock from "./SliderBlock";

const Slider = () => {
  var params = {
    spaceBetween: 30,
    shouldSwiperUpdate: true,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  };
  const data = useStaticQuery(graphql`
    {
      allStrapiHomeHeaders {
        nodes {
          slidersList {
            title
            paragraph
            link
            buttonName
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 60
                  height: 920
                  placeholder: NONE
                  transformOptions: { fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div className="container extending-core pt-4 pb-6">
      <Swiper {...params}>
        {data.allStrapiHomeHeaders.nodes[0].slidersList.map(
          (sliderData, index) => (
            <SliderBlock key={index} sliderData={sliderData} />
          )
        )}
      </Swiper>
    </div>
  );
};

export default Slider;

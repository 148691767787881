import React from "react";
import ReactMarkdown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const SliderBlock = ({ sliderData }) => {
  const { link, buttonName, paragraph, title, image } = sliderData;
  const createdImage = getImage(image);

  return (
    <div className="swiper-slide">
      <div className="row flex-row-reverse align-items-center">
        <div className="col-md-6">
          <GatsbyImage
            image={createdImage}
            className="img-responsive slider__img--w-auto"
            alt={`${title} slider image`}
          />
        </div>
        <div className="col-md-6 text-left">
          <h1
            className="lead text-muted"
          >
            <strong>{title}</strong>
            <ReactMarkdown source={paragraph} />
          </h1>
          <Link
            to={`${link}`}
            className="btn btn-primary btn-lg btn-rounded bold px-4 mt-4"
            title={`${link} page link`}
            noPrefetch
          >
            {buttonName}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SliderBlock;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const OurStrength = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiHomeSpecialities {
        nodes {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 60, width: 540, placeholder: NONE)
            }
          }
          specialityList {
            description
            title
            image {
              childImageSharp {
                gatsbyImageData(quality: 5, width: 54, placeholder: NONE)
              }
            }
          }
        }
      }
    }
  `);
  const {
    title,
    description,
    image,
    specialityList
  } = data.allStrapiHomeSpecialities.nodes[0];
  return (
    <div>
      <section className="section app-safety">
        <div className="shapes-container">
          <div className="shape shape-circle">
            <div
              data-aos="fade-up-left"
              className="aos-init aos-animate"
              style={{
                backgroundImage:
                  "linear-gradient(45deg,rgb(63, 151, 255)0, rgb(98, 255, 236) 100%)"
              }}
            />
          </div>
          <div
            className="shape shape-ring shape-ring-2"
            style={{ left: "50%", top: "15%" }}
          >
            <div
              data-aos="fade-up-right"
              data-aos-delay={200}
              className="aos-init aos-animate"
            />
          </div>
          <div className="shape pattern pattern-dots" style={{ top: "45%" }} />
        </div>
        <div className="container pb-4">
          <div className="row gap-y align-items-center">
            <div className="col-md-5">
              <div className="section-heading">
                <h2 className="bold">{title}</h2>
                <ReactMarkdown
                  source={description}
                  className="lead text-muted w-100"
                />
              </div>
              {specialityList.map((specialityData, index) => {
                const { description, title, image } = specialityData;
                const cardImage = getImage(image);
                return (
                  <div className="media pb-4" key={index}>
                    <div className="bg-white p-3  shadow-success rounded-circle d-flex align-items-center justify-content-center mr-4">
                      <GatsbyImage
                        image={cardImage}
                        style={{ width: 54, height: 54 }}
                        alt={`${title} image`}
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="bold">{title}</h5>
                      <ReactMarkdown source={description} />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className=" col-md-6  overflow-hidden offset-1 pl-5">
              <GatsbyImage
                image={getImage(image)}
                className="img-responsive remove-width-100 text-center"
                style={{ height: 500 }}
                alt="confident person image"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurStrength;

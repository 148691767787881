import React from "react";
import Form from "../../../common/ContactForm";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faGithub,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

import { formHome } from "../../../../config";

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiContactDetails {
        nodes {
          title
          address
          mail
          phone
        }
      }
    }
  `);
  const { title, address, mail } = data.allStrapiContactDetails.nodes[0];
  return (
    <>
      <section className="section shape-testimonials edge  ">
        <div className="shape-wrapper">
          <svg
            className="shape h-100"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 249.28 253.71"
          >
            <defs>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n\t\t\t\t\t\t\t.cls-1 {\n\t\t\t\t\t\t\t\topacity: 0.7;\n\t\t\t\t\t\t\t\tfill: url(#linear-gradient);\n\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t"
                }}
              />
              <linearGradient
                id="linear-gradient"
                y1="126.86"
                x2="249.28"
                y2="126.86"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset={0} stopColor="#2384fa" />
                <stop offset="0.23" stopColor="#3f97ff" />
                <stop offset="0.6" stopColor="#62ffec" />
                <stop offset={1} stopColor="#62ffec" />
              </linearGradient>
            </defs>
            <title>shape</title>
            <g id="Capa_2" data-name="Capa 2">
              <g id="OBJECTS">
                <path
                  className="cls-1"
                  d="M236.5,78.38a75.26,75.26,0,0,0-7.74-9.67l0,0S182.91,16.47,105.14,2l0,0A83.91,83.91,0,0,0,4.53,69.71C-11.34,147,19.55,209,19.73,209.4a75,75,0,0,0,6,10.77c19.06,28.33,54.33,39.57,86.73,30.43,0,0,52.47-12.9,111.35-71.37h0C251.68,152.56,257.84,110.1,236.5,78.38Z"
                />
              </g>
            </g>
          </svg>
        </div>

        <div className="container bring-to-front">
          <div className="row gap-y">
            <div className="col-md-5 pt-0 pb-0 ">
              <div className="d-block d-md-none section-heading">
                <h4 className="bold">{title}</h4>
              </div>
              <Form actionURL={formHome} />
            </div>
            <div className="col-md-5 ml-md-auto">
              <div className="d-none d-md-block section-heading mb-4">
                <h4 className="bold">{title}</h4>
              </div>
              <div className="media contact">
                <FontAwesomeIcon
                  icon={faMapMarker}
                  className="text-primary mr-3 font-l"
                />
                <div className="media-body">
                  <ReactMarkdown source={address} />
                </div>
              </div>

              <div className="media pt-4">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-primary mr-3 font-l"
                />
                <div className="media-body">
                  <a href="mailto:business@grootan.com">{mail}</a>
                </div>
              </div>
              <hr className="my-4" />
              <nav className="nav justify-content-center justify-content-md-start">
                <a
                  href="https://www.facebook.com/grootantech/"
                  className="tagmanager-facebook-home-contactus btn btn-circle btn brand-facebook mr-2"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={faFacebook} className="h4 text-white mb-0" />
                </a>{" "}
                <a
                  href="https://in.linkedin.com/company/grootan-technologies"
                  className="tagmanager-linkedin-home-contactus btn btn-circle btn brand-linkedin mr-2"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={faLinkedin} className="h4 text-white mb-0" />
                </a>{" "}
                <a
                  href="https://github.com/grootan"
                  className="tagmanager-github-home-contactus btn btn-circle btn brand-github"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={faGithub} className="h4 text-white mb-0" />
                </a>{" "}
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "../../../../styles/blog.css";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Casestudies = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiCasestudies(
        sort: { order: DESC, fields: updated_at }
        limit: 3
      ) {
        nodes {
          content
          description
          slug
          title
          published_at(formatString: "MMMM DD, YYYY")
          readTime
          image {
            childImageSharp {
              gatsbyImageData(quality: 10, width: 400, placeholder: NONE)
            }
          }
          casestudy_author {
            slug
            name
            role
          }
        }
      }
    }
  `);
  const { nodes: posts } = data.allStrapiCasestudies;

  return (
    <section className="section">
      <div className="container pt-0 pb-5">
        <div className="content">
          <div className="row py-5">
            <div className="col-lg-12 blogTitle text-center">
              <h3>Latest Case Studies</h3>
              <p>
                Initiatives we've delivered; We have a proven experience in
                providing solutions to a broad range of industries. Here we have
                mentioned a few case studies on how we deliver the power of
                digital in measurable ways
              </p>
            </div>

            {posts &&
              posts.map((post, index) => {
                const createdImage = getImage(post.image);
                return (
                  <div className={`col-lg-4 post-${index}`} key={index}>
                    <article className="blog-list-item row tile is-child post-featured">
                      <Link
                        to={"/casestudies/" + post.slug}
                        className="stretched-link"
                        title={`${post.title} page link`}
                      ></Link>
                      <div className="col-lg-12 blogroll-image px-0">
                        {post.image ? (
                          <div className="featured-thumbnail">
                            <GatsbyImage
                              image={createdImage}
                              alt={`${post.title} image`}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-12 content-box">
                        <div className="post-author">
                          <strong>
                            {post.casestudy_author.name} on {post.published_at}.
                          </strong>
                          <span> {post.readTime}</span>
                        </div>
                        <h3>{post.title}</h3>
                        <p>{post.description}</p>
                      </div>
                    </article>
                  </div>
                );
              })}
            <div className="col-md-12 d-flex justify-content-center">
              <a
                className="btn btn-primary btn-rounded btn-lg view-more text-none"
                href="/casestudies"
              >
                View More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Casestudies;

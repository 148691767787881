import React, { useEffect } from "react";
import Layout from "../components/layout";
import { Analytics } from "../components/common/Analytics";
import Home from "../components/views/home";

const HomePage = () => {
  useEffect(() => {
    Analytics();
  }, []);
  return (
    <Layout>
      <Home />
      <script src="./site.min.js" defer type="text/javascript" />
    </Layout>
  );
};

export default HomePage;

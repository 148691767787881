import React from "react";
import Slider from "./Slider";
import ArrowDown from "../../../img/integration/arrow-down.png";
import Contact from "./sections/Contact";
import Process from "./sections/Process";
import OurStrength from "./sections/OurStrength";
import Blog from "./sections/Blog";
import MetaData from "../../common/MetaData";
import { metaData } from "../../../config";
import Casestudies from "./sections/Casestudies";
const Home = () => {
  return (
    <main className="overflow-hidden">
      <header
        className="header integrations-header section h-auto min-vh-100 d-flex align-items-center"
      >
        <MetaData pageTitle="Home" description={metaData.home.description} keyword={metaData.home.keyword}/>
        <div className="shapes-container">
          <div
            className="shape shape-circle shape-circle-2"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div></div>
          </div>
          <div
            className="shape shape-square shape-square-1"
            data-aos="flip-right"
            data-aos-delay="400"
          >
            <div></div>
          </div>
          <div
            className="shape shape-square shape-square-2"
            data-aos="flip-left"
            data-aos-delay="400"
          >
            <div></div>
          </div>
          <div className="shape shape-ring shape-ring-1">
            <div></div>
          </div>
          <div className="shape shape-ring shape-ring-2">
            <div></div>
          </div>
          <div className="shape pattern pattern-dots">
            <div></div>
          </div>
          <div className="static-shape background-shape-main"></div>
        </div>
        <Slider />
        <a href="#process" className="godown" rel="link to our process section">
          <img src={ArrowDown} alt="navigate below arrow" />
        </a>
      </header>
      <Process />
      <Casestudies/>
      <OurStrength />
      <Blog />
      <Contact />
    </main>
  );
};

export default Home;

import React from "react";
import ProcessSlide from "../ProcessSlide";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

const Process = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiHomeProcesses {
        nodes {
          title
          description
          processList {
            sectionTitle
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 5, width: 54, placeholder: NONE)
              }
            }
          }
        }
      }
    }
  `);
  const {
    title,
    description,
    processList
  } = data.allStrapiHomeProcesses.nodes[0];

  return (
    <>
      <section className="section why-us overflow-hidden" id="process">
        <div className="shape-wrapper">
          <div
            className="absolute shape-background top right"
            style={{
              backgroundImage: "linear-gradient(to right,#62ffec,#3f97ff)"
            }}
          />
        </div>
        <div className="container pb-2">
          <div className="section-heading text-center">
            <h2 className="bold">{title}</h2>
            <ReactMarkdown
              className="lead text-secondary"
              source={description}
            />
          </div>
          <div className="row gap-y">
            <div className="col-md-12">
              <ul className="list-unstyled why-icon-list d-flex flex-column">
                {processList.map((processData, index) => (
                  <ProcessSlide
                    key={index}
                    index={index}
                    processData={processData}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Process;